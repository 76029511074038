<template>
<div class="tb-width-full gallery" id="gallery-detail">
  <div class="tb-wrap gallery-body">
    <template v-if="gallery">
        <div class="title-head">{{ gallery.name }}</div>
        <div class="title-sudhead">{{ gallery.content }}</div>
      <div class="gallery-wrap">
        <template v-if="gallery.photos">
          <template v-for="(item,itemIndex) in gallery.photos">
            <div class="gallery-item" :key="'gallery-' + item.id" @click="index = itemIndex">
              <div class="thumb">
                <img :src="item.cover_url" />
              </div>
              <div class="intro">
                <div class="intro-title">{{ item.name }}</div>
                <div class="intro-content">
                  {{ item.intro }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="empty-content title-2nd text-center">There's no content yet.</div>
        </template>

      </div>
    </template>
    <template v-else>
      <div class="gallery-wrap">
        <div class="empty-content title-2nd text-center">Loading...</div>
      </div>
    </template>
  </div>
  <CoolLightBox
    :items="items"
    :index="index"
    @close="index = null">
  </CoolLightBox>
</div>
</template>

<script>
import * as api from '@/api';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'Gallery',
  data () {
    return {
      itemId: this.$route.query.id,
      gallery: null,
      items: [],
      index: null
    };
  },
  components: {
    CoolLightBox
  },
  mounted () {
    const params = {
      id: this.itemId
    };
    console.log(params);
    api.gallery(params).then((res) => {
      this.gallery = res.result;
      console.log(`gallery.count:${this.gallery.photos.length}`);
      for (var i = 0; i < this.gallery.photos.length; i++) {
        const photo = this.gallery.photos[i];
        var item = {
          title: photo.name,
          description: photo.intro,
          src: photo.cover_url
        };
        this.items.push(item);
      }
      this.scrollTo();
    }).catch((e) => {
      this.gallery = null;
    });
  },
  methods: {
    scrollTo () {
      var options = {
        container: 'body',
        easing: 'ease-in',
        lazy: true,
        offset: -60,
        force: true,
        x: false,
        y: true
      };

      console.log('scroll');
      VueScrollTo.scrollTo('#gallery-detail', 200, options);

      // or alternatively inside your components you can use
      // cancelScroll = this.$scrollTo(element, duration, options);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/base";
@import "~@/assets/scss/gallery.scss";
</style>
